import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import '@popperjs/core'
import bootstrap from 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueSocialSharing from 'vue-social-sharing'
import VueLazyload from 'vue-lazyload'

const app = createApp(App)

const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const options = {
  // You can set your default options here
}

app.use(router)
.use(pinia)
.use(bootstrap)
.use(Toast, options)
.use(VueLazyload)
.use(VueSocialSharing)
.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBe3L5xXbSbj25wyT8Oluo7P_rHqaxBguI.'
  }
}).mount('#app')