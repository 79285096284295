import { acceptHMRUpdate, defineStore } from 'pinia'
import directionService from '@/services/direction.service'

export const useDirectionStore = defineStore({
  id: 'directionStore',
  state: () => ({ }),
  setup () {},
  actions: {
    getUserDirectionList () {
      return directionService.getUserDirectionList().then(
        response => {
          localStorage.setItem('duties', response.data.duties)
          localStorage.setItem('isSocialCategoriesSelected', response.data.isSocialCategoriesSelected)
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDirectionStore, import.meta.hot))
}
