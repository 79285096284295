import { acceptHMRUpdate, defineStore } from 'pinia'
import categoryService from '@/services/category.service'

export const useCategoryStore = defineStore({
  id: 'categoryStore',
  state: () => ({
    socialCategories: [],
    selectedCategoryIds: []
  }),
  setup () {},
  actions: {
    getCategoryList () {
      return categoryService.getCategoryList().then(
        response => {
          this.socialCategories = response.data.data.socialCategories
          return response
        }
      )
    },
    setSelectedCategoryIds () {
      const formData = {
        ids: this.selectedCategoryIds.join(',')
      }

      return categoryService.setSelectedCategoryIds(formData).then(
        response => {
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCategoryStore, import.meta.hot))
}
