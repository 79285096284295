import { BaseService } from './BaseService'
const SIGNUP_PATH = '/users/register'
const LOGIN_PATH = '/login'
const UPDATE_PASSWORD_PATH = '/users/update-password'
const FORGOT_PASSWORD_PATH = '/users/new-password/token'
const RESET_PASSWORD_PATH = '/users/reset-password'
const REFRESH_TOKEN_PATH = '/token/refresh'
const ACTIVATE_USER_PATH = '/users/approve-account'
/* eslint-disable */ 
class AuthService extends BaseService {
  signUp (formData) {
    return this.post(SIGNUP_PATH, formData)
  }
  login (formData) {
    return this.post(LOGIN_PATH, formData)
  }
  logout () {

  }
  renewToken (refreshToken) {
    return this.post(REFRESH_TOKEN_PATH, { refreshToken: refreshToken })
  }
  updatePassword (formData) {
    return this.put(UPDATE_PASSWORD_PATH, formData)
  }
  forgotPassword (formData) {
    return this.post(FORGOT_PASSWORD_PATH, formData)
  }
  resetPassword (formData) {
    return this.put(RESET_PASSWORD_PATH, formData)
  }
  activateUser (formData) {
    return this.post(ACTIVATE_USER_PATH, formData)
  }
}
export default new AuthService()
/* eslint-disable */ 
