import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CategoryView from '../views/CategoryView.vue'
import MapTestViewVue from '@/views/MapTestView.vue'

const routes = [
  {
    path: '/map-test',
    name: 'mapTest',
    component: MapTestViewVue
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home-map',
    name: 'home-map',
    component: () => import('../views/HomeMapView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/test-upload',
    name: 'testUpload',
    component: () => import('../views/UploadTestView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/first-mission',
    name: 'first mission',
    component: () => import('../views/FirstMission.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/duties',
    name: 'duties',
    component: () => import('../views/Duties.vue')
  },
  {
    path: '/duties-detail/:missionId/:locationId',
    name: 'duties-detail',
    component: () => import('../views/DutiesDetail.vue')
  },
  {
    path: '/category',
    name: 'category',
    component: CategoryView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: () => import('../views/AchievementsView.vue')
  },
  {
    path: '/new-password/:resetToken',
    name: 'new-password',
    component: () => import('../views/NewPassword.vue')
  },
  {
    path: '/profile-password',
    name: 'profile-password',
    component: () => import('../views/profilePassword.vue')
  },
  {
    path: '/profile-contact',
    name: 'profile-contact',
    component: () => import('../views/profileContact.vue')
  },
  {
    path: '/on-google-auth',
    name: 'on-google-auth',
    component: () => import('../views/SocialAuth.vue')
  },
  {
    path: '/on-facebook-auth',
    name: 'on-facebook-auth',
    component: () => import('../views/SocialAuth.vue')
  },
  {
    path: '/activate-user/:token',
    name: 'activate-user',
    component: () => import('../views/ActivateUserView.vue')
  },
  {
    path: '/coridor-plus',
    name: 'coridor-plus',
    component: () => import('../views/CoridorPlus.vue')
  },
  {
    path: '/subscription-options',
    name: 'subscription-options',
    component: () => import('../views/SubscriptionOptions.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
