import { acceptHMRUpdate, defineStore } from 'pinia'
import AuthService from '../services/auth.service'
import SocialLoginService from '../services/socialLogin.service'
import { useRoute, useRouter } from 'vue-router'
import checkKvkkService from '@/services/checkKvkk.service'
import approveKvkkService from '@/services/approveKvkk.service'

export const useAuthStore = defineStore({
  id: 'authStore',
  state: () => ({
    user: localStorage.getItem('user'),
    isLoggedIn: !(localStorage.getItem('token') === undefined || localStorage.getItem('token') === null),
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    isPremiumMember: false,
    route: useRoute(),
    router: useRouter()
  }),
  setup () {
    console.log('auth setup çalıştı')
    },
  actions: {
    checkIsLoggedIn () {
      if (this.isLoggedIn) return true
      else this.router.push('/login')

      return false
    },
    signUp (formData) {
      console.log(formData)
      return AuthService.signUp(formData)
    },
    login (formData) {
      return AuthService.login(formData).then(
        response => {
          console.log('Gelen Kullanıcı Bilgisi:', response.data);
          console.log(response)
          if (response.data !== undefined) {
            this.user = formData.username
            this.isLoggedIn = true
            this.token = response.data.token
            this.refreshToken = response.data.refresh_token

            // Premium olup olmadığını set et
            this.isPremiumMember = response.data.is_premium_member || false;
            console.log('Auth Kullanıcı Premium mu?:', this.isPremiumMember);

            localStorage.setItem('user', formData.username)
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('refreshToken', response.data.refresh_token)
            localStorage.setItem('isLogin', true)
          }
          return response
        }
      )
    },
    checkKvkk () {
      const formData = {
        socialLoginClientToken: localStorage.getItem('socialLoginId')
      }
      return checkKvkkService.checkKvkk(formData).then(
        response => {
          if (response.status === 200) {
            if (response.data.showAgreements === false) {
              return 1
            } else {
              return 2
            }
          }
          return 0
        })
    },
    sendSocialLoginId () {
      const formData = {
        socialLoginClientToken: localStorage.getItem('socialLoginId')
      }
      return SocialLoginService.sendSocialLoginId(localStorage.getItem('socialLoginName'), formData).then(
        response => {
          if (response.status === 200) {
            this.user = ''
            this.isLoggedIn = true
            this.token = response.data.token
            this.refreshToken = response.data.refreshToken
            localStorage.setItem('user', '')
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            localStorage.setItem('isLogin', true)
          }
          return response
        })
    },
    sendApproveKvkk () {
      const formData = {
        socialLoginClientToken: localStorage.getItem('socialLoginId')
      }
      return approveKvkkService.approveKvkk(formData)
    },
    logout () {
      this.user = null
      this.isLoggedIn = false
      this.token = null
      this.refreshToken = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('isSocialCategoriesSelected')
      localStorage.removeItem('duties')
      this.router.push('/login')
      localStorage.setItem('isLogin', false)
    },
    getToken () {
      return this.token
    },
    getRefreshToken () {
      return this.refreshToken()
    },
    renewToken () {
      this.token = null
      return AuthService.renewToken(this.refreshToken).then(
        response => {
          if (response.data !== undefined) {
            this.isLoggedIn = true
            this.token = response.data.token
            this.refreshToken = response.data.refresh_token

            localStorage.setItem('token', response.data.token)
            localStorage.setItem('refreshToken', response.data.refresh_token)
          }
          return response
        }
      )
    },
    activate () {
      // Aktivasyon işlemi için
      const token = this.route.params.token
      return AuthService.activate(token)
    },
    activationSignUp (formData) {
      // Kayıt+Aktivasyon işlemi için
      const token = this.route.params.token
      formData.invitation_token = token
      return AuthService.signUp(formData)
    },
    updatePassword (formData) {
      console.log(formData)
      return AuthService.updatePassword(formData)
    },
    forgotPassword (formData) {
      console.log(formData)
      return AuthService.forgotPassword(formData)
    },
    resetPassword (formData) {
      console.log(formData)
      return AuthService.resetPassword(formData)
    },
    activateUser (token) {
      const formData = {
        token: token
      }
      return AuthService.activateUser(formData)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
