import { BaseService } from './BaseService'
const CHECK_KVKK_LIST_PATH = '/check-kvkk'
const APPROVE_KVKK_LIST_PATH = '/send-kvkk-approve'
/* eslint-disable */
class CheckKvkkService extends BaseService {
  checkKvkk (formData) {
    return this.post(CHECK_KVKK_LIST_PATH, formData)
  }
  approveKvkk (formData) {
    return this.post(APPROVE_KVKK_LIST_PATH, formData)
  }
}
export default new CheckKvkkService()
/* eslint-disable */
